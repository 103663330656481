import ServiceVehicles from "../../service/vehicles/ServiceVehicles.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  customerVehiclesList: [],
  customerVehiclesCount: null,

  vehiclesCarjamSyncDataObjId: null,
};

export const mutations = {
  SET_CUSTOMER_VEHICLES_LIST(state, data) {
    state.customerVehiclesList = data;
  },

  SET_CUSTOMER_VEHICLES_COUNT(state, count) {
    state.customerVehiclesCount = count;
  },

  // vehicles carjam sync
  SET_VEHICLES_CARJAM_SYNC_DATA_OBJ_ID(state, objId) {
    state.vehiclesCarjamSyncDataObjId = objId;
  },

  CLEAR_VEHICLES_CARJAM_SYNC_DATA_OBJ_ID(state) {
    state.vehiclesCarjamSyncDataObjId = null;
  },
};

export const actions = {
  getCustomerVehiclesList(
    { commit },
    { customerUUID, perPage, page, searchQuery }
  ) {
    return ServiceVehicles.getCustomerVehiclesList(
      customerUUID,
      perPage,
      page,
      searchQuery
    )
      .then((resp) => {
        commit("SET_CUSTOMER_VEHICLES_LIST", resp.data.results);
        commit("SET_CUSTOMER_VEHICLES_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCustomerVehiclesListWithoutPagination({}, { customerUUID, searchQuery }) {
    return ServiceVehicles.getCustomerVehiclesListWithoutPagination(
      customerUUID,
      searchQuery
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveCustomerVehicle({}, { customerUUID, vehicleUUID }) {
    return ServiceVehicles.retrieveCustomerVehicle(customerUUID, vehicleUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  syncVehiclesCarjamData({ commit }, formData) {
    return ServiceVehicles.syncVehiclesCarjamData(formData)
      .then((resp) => {
        commit("SET_VEHICLES_CARJAM_SYNC_DATA_OBJ_ID", resp.data.id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Updating vehicles with Carjam data",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkVehiclesCarjamDataSyncStatus({ commit }, objId) {
    return ServiceVehicles.checkVehiclesCarjamDataSyncStatus(objId)
      .then((resp) => {
        if (resp.data.sync_data.status == "completed") {
          commit("CLEAR_VEHICLES_CARJAM_SYNC_DATA_OBJ_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
