import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  getCustomerVehiclesList(customerUUID, perPage, page, searchQuery) {
    let url =
      urlList.urlRoot +
      `customers/${customerUUID}/vehicles/` +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getCustomerVehiclesListWithoutPagination(customerUUID, searchQuery) {
    let url = urlList.urlRoot + `customers/${customerUUID}/vehicles/`;

    if (searchQuery) {
      url += "?search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  retrieveCustomerVehicle(customerUUID, vehicleUUID) {
    let url =
      urlList.urlRoot + `customers/${customerUUID}/vehicles/${vehicleUUID}/`;
    return apiClient.get(url);
  },

  syncVehiclesCarjamData(formData) {
    let url = urlList.urlRoot + "vehicles-carjam-sync/";
    return apiClient.post(url, formData);
  },

  checkVehiclesCarjamDataSyncStatus(objId) {
    let url = urlList.urlRoot + `vehicles-carjam-sync/${objId}/status/`;
    return apiClient.get(url);
  },
};
