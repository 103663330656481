import ServiceNotifier from "../../service/notifier/ServiceNotifier.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  customerChatsList: [],
  sentChatTaskId: null,
};

export const mutations = {
  SET_CUSTOMER_CHATS(state, chats) {
    state.customerChatsList = chats;
  },

  ADD_SENT_CHAT(state, chatObj) {
    state.customerChatsList.push(chatObj);
  },

  SAVE_SENT_CHAT_TASK_ID(state, taskId) {
    state.sentChatTaskId = taskId;
  },

  CLEAR_SENT_CHAT_TASK_ID(state) {
    state.sentChatTaskId = null;
  },

  CHANGE_SENT_CHAT_STATUS(state, { task_status, chatUUID }) {
    state.customerChatsList = state.customerChatsList.map((chatObj) => {
      if (chatObj.chat_uuid == chatUUID) {
        chatObj["status"] = task_status;
      }
      return chatObj;
    });
  },

  ADD_RESENT_CHAT(state, { chatUUID, updatedChatObj }) {
    state.customerChatsList = state.customerChatsList.filter((chatObj) => {
      if (chatObj.chat_uuid != chatUUID) {
        return chatObj;
      }
    });
    state.customerChatsList.push(updatedChatObj);
  },
};

export const actions = {
  // Customer chat
  retrieveCustomerChat({ commit }, customerUuid) {
    return ServiceNotifier.retrieveCustomerChat(customerUuid)
      .then((resp) => {
        commit("SET_CUSTOMER_CHATS", resp.data);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  sendChat({ commit }, formData) {
    return ServiceNotifier.sendChat(formData)
      .then((resp) => {
        commit("ADD_SENT_CHAT", resp.data.chat_obj);
        commit("SAVE_SENT_CHAT_TASK_ID", resp.data.task_id);
        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Sending message to customer!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";
          if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer[0];
          } else if (err.response.data.message_type) {
            message = "Message Type: " + err.response.data.message_type[0];
          } else if (err.response.data.message) {
            message = "Message: " + err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  resendChat({ commit }, formData) {
    return ServiceNotifier.resendChat(formData)
      .then((resp) => {
        commit("ADD_RESENT_CHAT", {
          chatUUID: resp.data.chat_obj.chat_uuid,
          updatedChatObj: resp.data.chat_obj,
        });
        commit("SAVE_SENT_CHAT_TASK_ID", resp.data.task_id);

        Notification.info({
          position: "bottom-right",
          duration: 2000,
          title: "Info",
          message: "Resending message to customer!",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.customer) {
            message = "Customer: " + err.response.data.customer[0];
          } else if (err.response.data.message_type) {
            message = "Message Type: " + err.response.data.message_type[0];
          } else if (err.response.data.message) {
            message = "Message: " + err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  checkSentMessageTaskStatus({ commit }, formData) {
    return ServiceNotifier.checkSentMessageTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_SENT_CHAT_TASK_ID");
          commit("CHANGE_SENT_CHAT_STATUS", {
            task_status: resp.data.task_status,
            chatUUID: resp.data.chat_uuid,
          });
        }
        return resp;
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      });
  },
};
