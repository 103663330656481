import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

const urlList = {
  urlRoot: "/api/v1/workshop/",
};

export default {
  getWorkshopVehiclesList(
    perPage,
    page,
    searchQuery,
    fuelTypes,
    bodyStyles,
    transmissions,
    driveWheels,
    statuses,
    owner
  ) {
    let url =
      urlList.urlRoot + "vehicles/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (fuelTypes) {
      url += "&fuel_types=" + fuelTypes;
    }
    if (bodyStyles) {
      url += "&body_styles=" + bodyStyles;
    }
    if (transmissions) {
      url += "&transmissions=" + transmissions;
    }
    if (driveWheels) {
      url += "&drive_wheels=" + driveWheels;
    }
    if (statuses) {
      url += "&statuses=" + statuses;
    }
    if (owner) {
      url += "&owner=" + owner;
    }

    return apiClient.get(url);
  },

  getWorkshopVehiclesListWithoutPagination(searchQuery) {
    let url = urlList.urlRoot + "vehicles/";

    if (searchQuery) {
      url += "?search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getCustomerWorkshopVehiclesList(customerUUID, perPage, page, searchQuery) {
    let url =
      urlList.urlRoot +
      `vehicles/customer-vehicles/${customerUUID}/` +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  addWorkshopVehicle(formData) {
    let url = urlList.urlRoot + "vehicles/";
    return apiClient.post(url, formData);
  },

  retrieveWorkshopVehicle(vehicleUUID) {
    let url = urlList.urlRoot + `vehicles/${vehicleUUID}/`;
    return apiClient.get(url);
  },

  updateWorkshopVehicle(vehicleUUID, formData) {
    let url = urlList.urlRoot + `vehicles/${vehicleUUID}/`;
    return apiClient.patch(url, formData);
  },

  getWorkshopVehicleFormData() {
    let url = urlList.urlRoot + "vehicles/form-data/";
    return apiClient.get(url);
  },

  getWorkshopVehicleDataFromCarjam(plateNo) {
    let url = urlList.urlRoot + "vehicles/search-carjam/?plate_no=" + plateNo;
    return apiClient.get(url);
  },

  // job kit category
  getJobKitCategoriesList(perPage, page, searchQuery, department) {
    let url =
      urlList.urlRoot +
      "job-kit-categories/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (department) {
      url += "&department=" + department;
    }

    return apiClient.get(url);
  },

  getJobKitCategoriesListWithoutPagination() {
    let url = urlList.urlRoot + "job-kit-categories/";
    return apiClient.get(url);
  },

  getJobKitCategoryFormData() {
    let url = urlList.urlRoot + "job-kit-categories/form-data/";
    return apiClient.get(url);
  },

  addJobKitCategory(formData) {
    let url = urlList.urlRoot + "job-kit-categories/";
    return apiClient.post(url, formData);
  },

  retrieveJobKitCategory(objId) {
    let url = urlList.urlRoot + `job-kit-categories/${objId}/`;
    return apiClient.get(url);
  },

  updateJobKitCategory(objId, formData) {
    let url = urlList.urlRoot + `job-kit-categories/${objId}/`;
    return apiClient.patch(url, formData);
  },

  deleteJobKitCategory(objId) {
    let url = urlList.urlRoot + `job-kit-categories/${objId}/`;
    return apiClient.delete(url);
  },

  // job kit
  getJobKitsList(perPage, page, searchQuery, department, categories) {
    let url =
      urlList.urlRoot + "job-kits/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (department) {
      url += "&department=" + department;
    }
    if (categories) {
      url += "&categories=" + categories;
    }

    return apiClient.get(url);
  },

  getJobKitsListWithoutPagination() {
    let url = urlList.urlRoot + "job-kits/";
    return apiClient.get(url);
  },

  getJobKitFormData() {
    let url = urlList.urlRoot + "job-kits/form-data/";
    return apiClient.get(url);
  },

  addJobKit(formData) {
    let url = urlList.urlRoot + "job-kits/";
    return apiClient.post(url, formData);
  },

  retrieveJobKit(jobKitUUID) {
    let url = urlList.urlRoot + `job-kits/${jobKitUUID}/`;
    return apiClient.get(url);
  },

  updateJobKit(jobKitUUID, formData) {
    let url = urlList.urlRoot + `job-kits/${jobKitUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteJobKit(jobKitUUID) {
    let url = urlList.urlRoot + `job-kits/${jobKitUUID}/`;
    return apiClient.delete(url);
  },

  // job
  getWorkshopJobsList(
    perPage,
    page,
    searchQuery,
    department,
    jobKitCategory,
    jobKits,
    serviceTypes,
    createdFrom,
    createdTo,
    status,
    vehicleUUID
  ) {
    let url = urlList.urlRoot + "jobs/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (department) {
      url += "&department=" + department;
    }
    if (jobKitCategory) {
      url += "&job_kit_category=" + jobKitCategory;
    }
    if (jobKits) {
      url += "&job_kits=" + jobKits;
    }
    if (serviceTypes) {
      url += "&service_types=" + serviceTypes;
    }
    if (createdFrom && createdTo) {
      url += "&created_from=" + createdFrom + "&created_to=" + createdTo;
    }
    if (status) {
      url += "&status=" + status;
    }
    if (vehicleUUID) {
      url += "&vehicle_uuid=" + vehicleUUID;
    }

    return apiClient.get(url);
  },

  getWorkshopCalendarJobsListWithoutPagination(startAt, endAt) {
    let url =
      urlList.urlRoot +
      "jobs/calendar-data/" +
      `?start_at=${startAt}&end_at=${endAt}`;
    return apiClient.get(url);
  },

  getCustomerWorkshopJobsList(customerUUID, perPage, page, searchQuery) {
    let url =
      urlList.urlRoot +
      `jobs/customer-jobs/${customerUUID}/` +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getMechanicDayJobsListWithoutPagination() {
    let url = urlList.urlRoot + "jobs/mechanic-day/";
    return apiClient.get(url);
  },

  addWorkshopJob(formData) {
    let url = urlList.urlRoot + "jobs/";
    return apiClient.post(url, formData);
  },

  addQuickJob(formData) {
    let url = urlList.urlRoot + "cash-sales/";
    return apiClient.post(url, formData);
  },

  updateQuickJob(jobUUID, formData) {
    let url = urlList.urlRoot + `cash-sales/${jobUUID}/`;
    return apiClient.patch(url, formData);
  },

  markQuickJobCompleted(jobUUID) {
    let url = urlList.urlRoot + `cash-sales/${jobUUID}/mark-completed/`;
    return apiClient.post(url);
  },

  retrieveWorkshopJob(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/`;
    return apiClient.get(url);
  },

  retrieveMechanicWorkshopJob(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/mechanic-job/`;
    return apiClient.get(url);
  },

  updateWorkshopJob(jobUUID, formData) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteWorkshopJob(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/`;
    return apiClient.delete(url);
  },

  recordWorkshopJobTime(jobUUID, formData) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/mechanic-time-record/`;
    return apiClient.post(url, formData);
  },

  markWorkshopJobFinished(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/mark-finished/`;
    return apiClient.post(url);
  },

  markWorkshopJobUnfinished(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/mark-unfinished/`;
    return apiClient.post(url);
  },

  markWorkshopJobCompleted(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/mark-completed/`;
    return apiClient.post(url);
  },

  generateWorkshopJobServiceReportPDF(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/generate-service-report-pdf/`;
    return apiClient.post(url);
  },

  checkWorkshopJobServiceReportPDFGenerationTaskStatus(jobUUID, formData) {
    let url =
      urlList.urlRoot + `jobs/${jobUUID}/service-report-pdf-task-status/`;
    return apiClient.post(url, formData);
  },

  shareWorkshopJobServiceReportWithCustomer(jobUUID, formData) {
    let url =
      urlList.urlRoot + `jobs/${jobUUID}/service-report/share/customer/`;
    return apiClient.post(url, formData);
  },

  workshopJobServiceReportCustomerView(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/service-report/customer-view/`;
    return apiClient.get(url);
  },

  getWorkshopJobFormData() {
    let url = urlList.urlRoot + "jobs/form-data/";
    return apiClient.get(url);
  },

  sendWorkshopJobPhotosDirectUploadUrl(jobUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/photos-upload-url/send/`;
    return apiClient.post(url);
  },

  getWorkshopJobPhotosInQuickUpload(base64Token) {
    let url = urlList.urlRoot + `jobs/${base64Token}/quick-photos-upload/`;
    return apiClient.get(url);
  },

  uploadWorkshopJobPhotosQuick(base64Token, formData) {
    let url = urlList.urlRoot + `jobs/${base64Token}/quick-photos-upload/`;
    return apiClient.post(url, formData);
  },

  // wof job data
  updateWofJobData(jobUUID, formData) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/update-wof-job-data/`;
    return apiClient.post(url, formData);
  },

  generateWofConflictFormPdf(jobUUID) {
    let url =
      urlList.urlRoot + `jobs/${jobUUID}/wof-conflict-form/generate-pdf/`;
    return apiClient.post(url);
  },

  checkWofConflictFormPdfTaskStatus(jobUUID, formData) {
    let url =
      urlList.urlRoot + `jobs/${jobUUID}/wof-conflict-form/pdf-task-status/`;
    return apiClient.post(url, formData);
  },

  // job document
  getWorkshopJobDocumentsList(jobUUID, perPage, page) {
    let url =
      urlList.urlRoot +
      `jobs/${jobUUID}/documents/?page_size=` +
      perPage +
      "&page=" +
      page;

    return apiClient.get(url);
  },

  addWorkshopJobDocument(jobUUID, formData) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/documents/`;
    return apiClient.post(url, formData);
  },

  updateWorkshopJobDocument(jobUUID, jobDocumentUUID, formData) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/documents/${jobDocumentUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteWorkshopJobDocument(jobUUID, jobDocumentUUID) {
    let url = urlList.urlRoot + `jobs/${jobUUID}/documents/${jobDocumentUUID}/`;
    return apiClient.delete(url);
  },

  // general service
  getWorkshopGeneralServicesList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot +
      "general-services/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getWorkshopGeneralServicesListWithoutPagination(searchQuery) {
    let url = urlList.urlRoot + "general-services/" + `?search=${searchQuery}`;
    return apiClient.get(url);
  },

  getWorkshopGeneralServicesFirst20List() {
    let url = urlList.urlRoot + "general-services/first-20/";
    return apiClient.get(url);
  },

  addWorkshopGeneralService(formData) {
    let url = urlList.urlRoot + "general-services/";
    return apiClient.post(url, formData);
  },

  retrieveWorkshopGeneralService(serviceUUID) {
    let url = urlList.urlRoot + `general-services/${serviceUUID}/`;
    return apiClient.get(url);
  },

  updateWorkshopGeneralService(serviceUUID, formData) {
    let url = urlList.urlRoot + `general-services/${serviceUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteWorkshopGeneralService(serviceUUID) {
    let url = urlList.urlRoot + `general-services/${serviceUUID}/`;
    return apiClient.delete(url);
  },

  getWorkshopGeneralServiceFormData() {
    let url = urlList.urlRoot + "general-services/form-data/";
    return apiClient.get(url);
  },
};
