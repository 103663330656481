import ServiceSettingsCrm from "../../service/settings/ServiceSettingsCrm.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {};

export const mutations = {};

export const actions = {
  getExternalAPIsSettings({}) {
    return ServiceSettingsCrm.getExternalAPIsSettings()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateExternalAPIsSettings({}, formData) {
    return ServiceSettingsCrm.updateExternalAPIsSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "External APIs Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.sendgrid_api_key) {
            message = "Sendgrid API Key: " + err.response.data.sendgrid_api_key;
          } else if (err.response.data.email_noreply) {
            message = "Email No-reply: " + err.response.data.email_noreply;
          } else if (err.response.data.carjam_api_base_url) {
            message =
              "Carjam API Base URL: " + err.response.data.carjam_api_base_url;
          } else if (err.response.data.carjam_api_key) {
            message = "Carjam API Key: " + err.response.data.carjam_api_key;
          } else if (err.response.data.etxt_api_key) {
            message = "eTXT API Key: " + err.response.data.etxt_api_key;
          } else if (err.response.data.etxt_api_secret) {
            message = "eTXT API Secret: " + err.response.data.etxt_api_secret;
          } else if (err.response.data.google_places_api_key) {
            message =
              "Google Places API Key: " +
              err.response.data.google_places_api_key;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  // sms provider settings
  retrieveCompanies() {
    return ServiceSettingsCrm.retrieveCompanies()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getSmsProviderSettings({}, { perPage, page, searchQuery }) {
    return ServiceSettingsCrm.getSmsProviderSettings(perPage, page, searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  retrieveSmsProviderSettings({}, settingsUUID) {
    return ServiceSettingsCrm.retrieveSmsProviderSettings(settingsUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  createSmsProviderSettings({}, formData) {
    return ServiceSettingsCrm.createSmsProviderSettings(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "SMS Provider Settings created successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company) {
            message = "Company: " + err.response.data.company;
          } else if (err.response.data.api_key) {
            message = "API Key: " + err.response.data.api_key;
          } else if (err.response.data.api_secret) {
            message = "API Secret: " + err.response.data.api_secret;
          } else if (err.response.data.phone_no) {
            message = "Phone No.: " + err.response.data.phone_no;
          } else if (err.response.data.webhook_api_key) {
            message = "Webhook API Key: " + err.response.data.webhook_api_key;
          } else if (err.response.data.webhook_account_name) {
            message =
              "Webhook Account Name: " + err.response.data.webhook_account_name;
          }

          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: message,
          });
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateSmsProviderSettings({}, { formData, settingsUUID }) {
    return ServiceSettingsCrm.updateSmsProviderSettings(formData, settingsUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "SMS Provider Settings updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.company) {
            message = "Company: " + err.response.data.company;
          } else if (err.response.data.api_key) {
            message = "API Key: " + err.response.data.api_key;
          } else if (err.response.data.api_secret) {
            message = "API Secret: " + err.response.data.api_secret;
          } else if (err.response.data.phone_no) {
            message = "Phone No.: " + err.response.data.phone_no;
          } else if (err.response.data.webhook_api_key) {
            message = "Webhook API Key: " + err.response.data.webhook_api_key;
          } else if (err.response.data.webhook_account_name) {
            message =
              "Webhook Account Name: " + err.response.data.webhook_account_name;
          }

          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: message,
          });
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getSmsProviderSettingsFormData({}, companyUUID) {
    return ServiceSettingsCrm.getSmsProviderSettingsFormData(companyUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
