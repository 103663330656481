import ServiceXeroManager from "../../service/xero_manager/ServiceXeroManager.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {};

export const mutations = {};

export const actions = {
  getXeroLoginInfo({}) {
    return ServiceXeroManager.getXeroLoginInfo()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  revokeXeroToken({}, formData) {
    return ServiceXeroManager.revokeXeroToken(formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Xero connection revoked successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.user_password) {
            message = "Password: " + err.response.data.user_password;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },
};
