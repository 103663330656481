import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

const urlList = {
  urlRoot: "/api/v1/notifier/",
};

export default {
  retrieveCustomerChat(customerUuid) {
    let url =
      urlList.urlRoot +
      "customer-chats/" +
      "?customer_uuid=" +
      `${customerUuid}`;
    return apiClient.get(url);
  },

  sendChat(formData) {
    let url = urlList.urlRoot + "customer-chats/";
    return apiClient.post(url, formData);
  },

  resendChat(formData) {
    let url = urlList.urlRoot + `customer-chats/resend/`;
    return apiClient.post(url, formData);
  },

  checkSentMessageTaskStatus(formData) {
    let url = urlList.urlRoot + "customer-chats/sent-chat-task-status/";
    return apiClient.post(url, formData);
  },
};
