import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
});

const urlList = {
  urlRoot: "/api/v1/contacts/",
};

export default {
  getCustomersList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "customers/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getCustomersListWithoutPagination(searchQuery) {
    let url = urlList.urlRoot + "customers/";

    if (searchQuery) {
      url += "?search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  addCustomer(formData) {
    let url = urlList.urlRoot + "customers/";
    return apiClient.post(url, formData);
  },

  retrieveCustomer(customerUUID) {
    let url = urlList.urlRoot + `customers/${customerUUID}/`;
    return apiClient.get(url);
  },

  fetchAndCreateXeroCustomers() {
    let url = urlList.urlRoot + "customers/fetch-xero/";
    return apiClient.post(url);
  },

  syncCustomerInXero(customerUUID) {
    let url = urlList.urlRoot + `customers/${customerUUID}/sync-xero/`;
    return apiClient.post(url);
  },

  updateCustomer(customerUUID, formData) {
    let url = urlList.urlRoot + `customers/${customerUUID}/`;
    return apiClient.patch(url, formData);
  },

  checkXeroCustomerTaskStatus(formData) {
    let url = urlList.urlRoot + `customers/xero-task-status/`;
    return apiClient.post(url, formData);
  },

  checkCustomerEmailDuplicate(formData) {
    let url = urlList.urlRoot + "customers/check-email-duplicate/";
    return apiClient.post(url, formData);
  },

  getCustomerFormData() {
    let url = urlList.urlRoot + "customers/form-data/";
    return apiClient.get(url);
  },
};
