import ServiceAnalytics from "../../service/analytics/ServiceAnalytics.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {};

export const mutations = {};

export const actions = {
  getDashboardStats({}, { timePeriod, department }) {
    return ServiceAnalytics.getDashboardStats(timePeriod, department)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobsRevenueByProductType({}, { productTypeSlug, timePeriod }) {
    return ServiceAnalytics.getJobsRevenueByProductType(
      productTypeSlug,
      timePeriod
    )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getJobMetrics({}, jobUUID) {
    return ServiceAnalytics.getJobMetrics(jobUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
